import BaseController from "./BaseController";
import { delay } from "../utils/generalHelper";

class Controller extends BaseController {
  constructor() {
    super();
    this.SignUpURL = "/sign-up";
    this.ResendOtpURL = "/otp/resend";
    this.ValidateMobileURL = "/sign-up/validate-mobile";
    this.ValidateOtpURL = "/sign-up/validate-mobile/otp";
    this.ValidateStatusURL = "/sign-up/status";
    this.SetPasswordURL = "/sign-up/set-password";
    this.SecurityQuestionsMobile = "/sign-up/security-question/mobile";
    this.SecurityQuestionsAddress = "/sign-up/security-question/address";
  }

  signUp = (body) => {
    return this.sendRequest("POST", this.SignUpURL, null, body);
  };

  resendOtp = (body) => {
    return this.sendRequest("POST", this.ResendOtpURL, null, body);
  };

  validateMobile = () => {
    return this.sendRequest("POST", this.ValidateMobileURL);
  };

  validateOtp = (body) => {
    return this.sendRequest("POST", this.ValidateOtpURL, null, body);
  };

  validateStatus = () => {
    return this.sendRequest("GET", this.ValidateStatusURL);
  };

  setPassword = (body) => {
    return this.sendRequest("POST", this.SetPasswordURL, null, body);
  };

  getSecurityQuestionsMobile = () => {
    return this.sendRequest("GET", this.SecurityQuestionsMobile);
  };

  postSecurityQuestionsMobile = (body) => {
    return this.sendRequest("POST", this.SecurityQuestionsMobile, null, body);
  };

  getSecurityQuestionsAddress = () => {
    return this.sendRequest("GET", this.SecurityQuestionsAddress);
  };

  postSecurityQuestionsAddress = (body) => {
    return this.sendRequest("POST", this.SecurityQuestionsAddress, null, body);
  };

  checkStatus = async () => {
    let retries = 0;
    let result = {
      isReportPulled: false,
      isAccountVerified: false,
      isMobileVerified: false,
      errorMsg: "",
    };

    while (retries < 10) {
      const statusResponse = await this.validateStatus();

      if (!statusResponse) {
        // No response received, throw error
        throw Error("No response from server");
      }

      if (statusResponse.credit_report_ready) {
        result.isReportPulled = true;
        result.isAccountVerified = statusResponse.account_verified;
        result.isMobileVerified = statusResponse.mobile_verified;
        result.errorMsg = "";
        break;
      }

      await delay(3000);
      retries++;
    }

    // Set error message if report could not be retrieved
    if (!result.isReportPulled) {
      result.errorMsg =
        "Your credit report couldn't be retrieved at this time, please try again later. We apologise for the inconvenience.";
    }

    return result;
  };
}

const SignUpController = new Controller();

export default SignUpController;
