import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import LoginSignupContainer from "../../components/templates/LoginSignupContainer";
import InitialForm from "./StepComponents/InitialForm";
import CreditProfileForm from "./StepComponents/CreditProfileForm";
import TermsAndConditionsForm from "./StepComponents/TermsAndConditionsForm";
import OtpVerificationForm from "./StepComponents/OtpVerificationForm";
import AccountNotAutoVerified from "./StepComponents/AccountNotAutoVerified";
import SignupCompleted from "./StepComponents/SignupCompleted";
import SecurityQuestionForm from "./StepComponents/SecurityQuestionForm";
import { Typography } from "@mui/material";

function SignUpPage() {
  const maxSteps = 7;
  const { state } = useLocation();
  const { login, logout } = useAuthContext();

  const [step, setStep] = useState(
    state?.verifyMobile ? 4 : state?.verifyAccount ? 6 : 1,
  );
  const [signupFormValues, setSignupFormValues] = useState({});

  const handleTCs = (proceed) => {
    if (proceed === true) {
      handleForward();
    } else {
      handleBackward();
    }
  };

  const handleOtpVerification = (requiresSecurityQuestions, noReport) => {
    if (requiresSecurityQuestions === true) {
      handleForward();
    } else if (noReport === true) {
      // No credit report was pulled, navigate back to login screen
      logout(true);
    } else {
      // Signup is completed
      setStep(maxSteps);
    }
  };

  const handleSecurityQuestion = (proceed) => {
    if (proceed) {
      handleForward();
    } else {
      logout(true);
    }
  };

  const loginPromise = () => {
    return new Promise(async (res, rej) => {
      try {
        const credentials = {
          id_number: signupFormValues.id_number,
          password: signupFormValues.password,
        };

        const responseMsg = await login(credentials);

        if (!responseMsg || responseMsg === "success") {
          res(true);
        } else {
          rej(false);
        }
      } catch (error) {
        rej(error);
      }
    });
  };

  const handleForward = () => {
    setStep(step + 1);
  };

  const handleBackward = () => {
    setStep(step - 1);
  };

  const getStepContents = () => {
    switch (step) {
      case maxSteps:
        return <SignupCompleted loginHandler={loginPromise} />;
      case 6:
        return <SecurityQuestionForm submitCallback={handleSecurityQuestion} />;
      case 5:
        return <AccountNotAutoVerified submitCallback={handleForward} />;
      case 4:
        return (
          <OtpVerificationForm
            submitCallback={handleOtpVerification}
            verifyMobile={state?.verifyMobile}
          />
        );
      case 3:
        return (
          <TermsAndConditionsForm
            formValues={signupFormValues}
            submitCallback={handleTCs}
            handleBack={getHandleBack()}
          />
        );
      case 2:
        return (
          <CreditProfileForm
            initialFormValues={signupFormValues}
            setFormValues={setSignupFormValues}
            submitCallback={handleForward}
          />
        );
      case 1:
      default:
        return (
          <InitialForm
            initialFormValues={signupFormValues}
            setFormValues={setSignupFormValues}
            submitCallback={handleForward}
          />
        );
    }
  };

  const getPageTitle = () => {
    switch (step) {
      case 4:
        return (
          <Typography component="h2" variant="pageTitle2" marginY={4}>
            OTP Verification
          </Typography>
        );
      case 2:
        return (
          <Typography component="h2" variant="pageTitle2" marginTop={4}>
            Onward to Your Credit Profile
          </Typography>
        );
      case 1:
        return (
          <>
            <Typography component="h1" variant="pageTitle1" marginTop={4}>
              Sign Up Now
            </Typography>
            <Typography component="p">FREE Credit Score!</Typography>
            <Typography component="p" marginBottom={4} fontStyle="italic">
              Elevate Your Credit, Elevate Your Life.
            </Typography>
          </>
        );
      default:
        return null;
    }
  };

  const getHandleBack = () => {
    if (step > 3)
      return () => {
        logout(true);
      };

    if (step > 1) return handleBackward;
    else return null;
  };

  return (
    <LoginSignupContainer
      pageTitle={getPageTitle()}
      overwriteHandleBack={getHandleBack()}
      showBackButton={[2, 3].includes(step)}
      showLogo={true}
    >
      {getStepContents()}
    </LoginSignupContainer>
  );
}

export default SignUpPage;
