import React from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import "./index.css";
import App from "./App";
import customTheme from "./constants/Theme";
import AuthContextProvider from "./contexts/AuthContext";
import { initGA } from "./utils/analyticsHelper";

// Check environment and add GA script if in production
const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

if (trackingId) {
  initGA(trackingId);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <SnackbarProvider maxSnack={3} preventDuplicate autoHideDuration={3000}>
        <CssBaseline />
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
