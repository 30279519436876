import ReactGA from "react-ga4";
import { appTitle } from "../constants/appData";
import { normaliseString } from "./generalHelper";

export const initGA = (trackingId) => {
  ReactGA.initialize(trackingId);
};

export const logPageView = () => {
  const pathName = window.location.pathname;
  const pathSplit = pathName?.split("/").filter(Boolean);
  const titleExt = pathSplit.length > 0 ? pathSplit.join(" ") : "Landing";
  const fullTitle = appTitle + " - " + normaliseString(titleExt);

  ReactGA.send({ hitType: "pageview", page: pathName, title: fullTitle });
};
